.login-box {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  @media (max-width: $medium-break) {
    position: static;
    transform: none;
  }
}

.login {
  position: absolute;
  top: 121px;
  right: -128px;
  z-index: 1;
  max-width: 300px;
  @media (max-width: $medium-break) {
    position: static;
    max-width: 100%;
    background-color: #133336;
    border-bottom: 5px solid rgb(187, 191, 183);
  }
  > .row {
    margin: 0;
    @media (max-width: $medium-break) {
      padding: 0 30px;
      border-bottom: 5px solid #fff;
    }
  }
  input {
    width: 100%;
    padding: 10px;
    margin: 0;
    background-color: $input-background;
    border: 0;
    &[type='text'],
    &[type='password'] {
      @media (max-width: $medium-break) {
        padding: 12px 18px;
        margin: 2px 8px;
        font-size: 1.35em;
        background: #0a2628;
      }
      @media (max-width: $small-break) {
        margin: 2px 0;
      }
    }
    &[type='submit'] {
      @media (max-width: $medium-break) {
        padding: 6px 18px;
        margin-top: 4px;
        font-size: 1.15em;
        font-weight: 700;
        color: $dark-font-color;
        text-transform: uppercase;
        background: $button-color;
      }
    }
    @media (max-width: $medium-break) {
      margin: 2px 8px;
    }
    @media (max-width: $small-break) {
      margin: 2px 0;
    }
  }
  .styled-select {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    background-color: $select-background;
    @media (max-width: $medium-break) {
      margin: 2px 8px;
      background-color: rgba(230, 231, 225, 0.1);
    }
    @media (max-width: $small-break) {
      margin: 2px 0;
    }
    &:before {
      position: absolute;
      top: -10%;
      right: 20px;
      display: inline;
    }
  }
  select.role-list {
    position: relative;
    width: 115%;
    padding: 15px;
    margin: 1px 0;
    background-color: transparent;
    border: 0;
    @media (max-width: $medium-break) {
      padding: 13px;
      font-size: 1.25em;
      color: rgba(255, 255, 255, 0.75);
    }
  }
  .login-fields {
    padding: 0;
    @media (max-width: $medium-break) {
      padding: 30px 0;
    }
  }
  .login-container {
    width: 100%;
    height: 50px;
    padding: 6px 15px;
    background-color: rgba(5, 7, 0, 0.7);
    @media (max-width: $medium-break) {
      height: 55px;
      margin: 4px 8px;
    }
    @media (max-width: $small-break) {
      margin: 4px 0;
    }
    @media (max-width: $xs-break) {
      height: auto;
    }
    a {
      display: block;
      @media (max-width: $medium-break) {
        font-size: 0.95em;
        white-space: nowrap;
      }
    }
    .columns {
      padding-left: 0;
      @media (max-width: $xs-break) {
        float: none;
        width: 100%;
        input[type='submit'] {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
    div:nth-child(2n) {
      text-align: right;
    }
    button {
      width: 95px;
      padding: 5px;
      margin-top: 4px;
      cursor: pointer;
      background-color: $link-color;
      border: 0;
      &:hover {
        color: $primary-color;
        background-color: #fff;
      }
    }
  }
  .did-you-know {
    padding: 10px 15px 30px;
    margin-top: 14px;
    background-color: rgba(5, 58, 51, 0.9);
    @media (max-width: $medium-break) {
      margin-top: 0;
      font-size: 0.95em;
      background: transparent;
      div {
        color: #b5c5c7;
      }
    }
    @media (max-width: $small-break) {
      padding: 0 0 30px;
    }
  }
}
