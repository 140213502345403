// colors
$primary-color: rgb(12, 110, 117);
$secondary-color: rgb(221, 223, 219);
$primary-color-light: rgb(28, 187, 180);
$white-font-color: rgb(255, 255, 255);
$light-font-color: rgb(181, 197, 199);
$middle-font-color: rgb(178, 179, 177);
$dark-font-color: rgb(62, 64, 61);
$link-color: rgb(218, 174, 60);
$button-color: rgba(255, 197, 54, 0.88);
$input-background: rgba(62, 64, 61, 0.85);
$select-background: rgba(230, 231, 225, 0.88);

// fonts
$franklin-comp: 'League Gothic', sans-serif;
$franklin-cond: 'Roboto Condensed', sans-serif;
$franklin: 'Roboto', sans-serif;
$adriane: 'Lora', serif;
$large-font-size: 20px;
$body-font-size: 16px;
$small-font-size: 15px;
$extra-small-font-size: 14px;

//Screen Breaks
$medium-break: 1025px;
$small-break: 640px;
$xs-break: 321px;
