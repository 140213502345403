/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/

html,
body {
  position: relative;
  height: 100%;
  background: white;
}

.is-bar {
  padding: 4px 0;
  background-color: $primary-color;
}

.container.row {
  max-width: 1020px !important;
}

.row .row {
  margin-top: 30px;
  @media (max-width: $medium-break) {
    margin-top: 0;
  }
  @media (max-width: $small-break) {
    padding-bottom: 0;
  }
}

p {
  margin: 0;
}

.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
}

.clear {
  display: block;
  clear: both;
}
