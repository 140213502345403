section {
  @media (max-width: $medium-break) {
    padding: 0 30px;
  }
}

section .row,
footer .row {
  padding-bottom: 1px;
  margin-top: 30px;
  @media (max-width: $medium-break) {
    padding-bottom: 60px;
    margin-top: 0;
  }
  &.toggle-viewable-area {
    position: relative;
    height: 101px;
    margin-top: -37px;
    clear: both;
    cursor: pointer;
    div {
      padding: 8px 0 24px;
      margin-top: -8px;
      i {
        display: block;
        margin-top: -4px;
      }
      &.see-less {
        display: none;
      }
    }
  }
}

section .collapsable {
  height: 350px;
  overflow: hidden;
  @media (max-width: $medium-break) {
    height: 500px;
  }
}

/**** Section Specific ****/

.main {
  padding: 120px 0 5px;
  @media (max-width: $medium-break) {
    padding-bottom: 0;
  }
  .cycle-slideshow {
    z-index: 1;
    max-width: 1280px;
    margin: 0 auto;
    @media (min-width: $medium-break) {
      img {
        height: 480px;
        min-width: 1280px;
      }
    }
  }
  > div {
    width: 100%;
    overflow: hidden;
    @media (min-width: $medium-break) {
      min-height: 480px;
    }
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
}

.about-us {
  h2 {
    margin-top: 0;
  }
  ul.bulleted {
    padding-left: 0;
    list-style: none;
  }
  .row {
    padding-bottom: 30px;
    .two-col-txt {
      padding-right: 30px;
      padding-left: 30px;
      text-align: justify;
    }
  }
}

@media (max-width: $medium-break) and (min-width: $small-break) {
  .medium-6.columns:last-child {
    padding-right: 0;
  }
  .medium-6.columns:first-child {
    padding-left: 0;
  }
}

.services {
  min-height: 480px;
  //Forces a break between the sections, so that they don't interact with one another
  border-top: 1px solid rgba(0, 0, 0, 0);
  @media (max-width: $medium-break) {
    padding-top: 40px;
  }
  @media (max-width: $small-break) {
    min-height: 460px;
  }
  h1 {
    padding: 0;
  }
  h2 {
    display: table-cell;
    height: 116px;
    padding-bottom: 15px;
    vertical-align: bottom;
    @media (max-width: $medium-break) {
      height: 56px;
    }
  }
  > div > .columns {
    &:last-child {
      float: left;
      @media (max-width: $medium-break) {
        float: right;
      }
    }
    &:first-child {
      float: right;
      @media (max-width: $medium-break) {
        float: left;
      }
    }
  }
  .collapsable {
    @media (min-width: $small-break) {
      height: auto;
    }
  }
  .row {
    > .columns {
      padding-left: 2px;
      @media (max-width: $small-break) {
        padding-left: 10px;
      }
      > div:nth-child(1) {
        padding-left: 0;
        @media (max-width: $medium-break ){
          padding-left: 15px;
        }
        li:before {
          left: -25px;
          @media (max-width: $medium-break) {
            left: -10px;
          }
        }
      }
    }
  }
  ul.check-list {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
    li {
      margin: 15px 0;
      &:before {
        position: absolute;
        left: -10px;
        display: inline-block;
        margin-right: 1px;
      }
    }
  }
  .contiainer {
      overflow: visible;
  }
}

.choosing-quorum {
  @media (max-width: $medium-break) {
    padding-top: 40px;
  }
  h2 {
    margin-bottom: 0;
  }
  blockquote {
    margin-left: 0;
    .quote {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
}

.who-we-serve {
  height: 480px;
  //Forces a break between the sections, so that they don't interact with one another
  border-top: 1px solid rgba(0, 0, 0, 0);
  @media (max-width: $medium-break) {
    height: 520px;
    background: transparent url(http://quorum.local/Themes/Quorum/assets/dist/images/who_we_serve_background_TBLT.png) no-repeat 0;
    background-position: right 40vw top;
    background-size: contain;
    .container {
      position: relative;
    }
  }
  @media (max-width: $small-break) {
    background: transparent;
  }
  .who-we-serve-list {
    @media (max-width: $medium-break) {
      position: absolute;
      right: 30px;
      bottom: 60px;
      width: 45%;
    }
    @media (max-width: $small-break) {
      position: static;
      width: 100%;
      margin-top: 100px;
    }
  }
  ul.chevron-list {
    padding-left: 0;
    list-style: none;
    li {
      margin: 10px 0;
      white-space: nowrap;
      &:before {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .section-title {
    height: 480px;
    padding-top: 30px;
    padding-right: 130px;
    margin-top: -30px;
    margin-right: -130px;
    background: $secondary-color url('../images/who_we_serve_background.png') no-repeat 270px 0;
    @media (max-width: $medium-break) {
      height: 499px;
      margin-right: auto;
      background: transparent;
    }
    h1 {
      white-space: nowrap;
      @media (max-width: $medium-break) {
        float: right;
        width: 50%;
        margin-top: 0;
        text-align: left;
      }
      @media (max-width: $small-break) {
        position: absolute;
        float: none;
        width: 100%;
      }
    }
    @media (max-width: $medium-break) {
      margin-top: 0;
    }
  }
}

footer.contact .contact-card {
  padding-left: 0;
}
