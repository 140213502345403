.contact {
  padding: 30px 0 70px;
  //Forces a break between the sections, so that they don't interact with one another
  border-top: 1px solid rgba(0, 0, 0, 0);
  @media (max-width: $medium-break) {
    padding-right: 30px;
    padding-left: 30px;
  }
  h2 {
    margin: 0;
  }
}
