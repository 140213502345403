body {
  margin: 0;
  border-top: 5px solid $primary-color;
}

header {
  .fixed {
    position: fixed;
    z-index: 4;
    width: 100vw !important;
    height: 120px;
    max-width: none !important;
    background-color: #fff;
  }
  .logo {
    height: 55px;
    margin-top: 30px;
    text-indent: -999999px;
    cursor: pointer;
  }
}
