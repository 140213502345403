html,
body {
  font-family: $adriane;
  font-size: $body-font-size;
  color: $dark-font-color;

  .large-copy {
    font-size: $large-font-size;
  }

  .small-copy {
    font-size: $small-font-size;
  }

  .extra-small-copy {
    font-size: $extra-small-font-size;
  }
}

nav {
  font-family: $franklin-cond;
  font-weight: 700;
  line-height: 55px;

  @media (max-width: $medium-break) {
    line-height: 45px;
  }

  ul {
    text-align: right;

    li a {
      font-size: 1.05em;
      font-weight: bold;
      color: $dark-font-color;
      text-decoration: none;
      text-transform: uppercase;

      @media (max-width: $medium-break) {
        font-size: 1.15em;
        font-weight: 500;
      }

      &:hover {
        color: $dark-font-color;
        text-decoration: none;
      }

      &:visited,
      &:active {
        color: $dark-font-color;
      }
    }
  }
}

.login {
  a {
    font-size: 12px;
    line-height: 19px;
  }

  .styled-select:before {
    font-family: FontAwesome;
    font-size: 32px;
    color: $primary-color;
    content: '\f0dd';

    @media (max-width: $medium-break) {
      color: #3bb8b4;
    }
  }

  select.role {
    font-family: $franklin;
    font-size: 15px;
    color: $dark-font-color;

    .forgot {
      text-align: top;
    }
  }

  .login-container {
    font-family: $franklin;

    button {
      font-family: $franklin-cond;
      font-size: 17px;
      font-weight: 700;
      color: $dark-font-color;
      text-transform: uppercase;
    }
  }

  .did-you-know {
    font-family: $franklin;
    font-size: 12px;
    font-weight: 500;
    color: #fff;

    @media (max-width: $medium-break) {
      font-size: 1.05em;
    }

    h3 {
      font-family: $franklin-comp;
      font-size: 24px;
      font-weight: 100;
      line-height: 0;
      text-transform: uppercase;

      @media (max-width: $medium-break) {
        font-size: 28px;
        color: #b8c1c0;
      }
    }

    h4 {
      font-size: 15px;
      line-height: 0;

      @media (max-width: $medium-break) {
        font-size: 18px;
        color: #f2f2f2;
      }
    }

    div {
      margin: 6px 0;
      color: #aabcc0;
    }

    .highlighted-text {
      color: #38aea6;
    }
  }
}

section .row,
footer .row {
  &.dark {
    color: $light-font-color;

    h1,
    h2 {
      color: $white-font-color;
    }
  }

  &.toggle-viewable-area {
    text-align: center;

    &.white {
      div {
        color: $primary-color;
      }
    }

    div {
      font-size: $extra-small-font-size;
      color: $primary-color;
      text-transform: uppercase;
    }
  }
}

.is-bar {
  font-family: $franklin;
  color: #fff;
  text-align: center;
}

h1 {
  font-family: $franklin-comp;
  font-size: 64px;
  font-weight: 200;
  line-height: 0.8;
  color: #0c6e75;
  text-transform: uppercase;

  @media (max-width: $medium-break) {
    margin: 50px 0 30px 0;
  }
}

h2 {
  font-family: $franklin;
  font-size: 1em;
}

input {
  color: #fff;

  &[placeholder] {
    font-family: $franklin;
    font-size: 17px;
    font-style: italic;
  }
}

.blue-text {
  color: $primary-color;
}

.light-text {
  color: $light-font-color;
}

.dark-text {
  color: $dark-font-color;
}

.styled-select:before {
  font-family: FontAwesome;
  font-size: 32px;
  color: $primary-color;
  content: '\f0dd';
}

.about-us ul.bulleted li {
  text-indent: -12px;

  &:before {
    margin-right: 5px;
    color: $primary-color;
    content: '•';
  }
}

ul.check-list li:before {
  font-family: FontAwesome;
  color: $primary-color-light;
  content: '\f00c';
}

.choosing-quorum {
  blockquote {
    .quote {
      font-style: italic;

      @media (max-width: $small-break) {
        font-size: 1.25em;
        font-weight: 700;
      }
    }

    .by {
      font-weight: bold;
    }
  }
}

.who-we-serve {
  h1 {
    color: $dark-font-color;
    text-align: right;
  }

  ul.chevron-list {
    li {
      font-family: $franklin;
      font-size: 36px;
      text-indent: -5px;

      @media (max-width: $xs-break) {
        font-size: 30px;
      }

      &:before {
        font-family: FontAwesome;
        font-size: 26px;
        line-height: 36px;
        color: $white-font-color;
        vertical-align: 7%;
        content: '\f054';
      }
    }
  }
}

footer.contact {
  font-family: $franklin;

  h2 {
    font-size: 18px;
  }

  .info {
    font-size: 15px;

    &.copyright {
      text-align: right;

      @media (max-width: $small-break) {
        padding-left: 0;
        margin-top: 15px;
        text-align: left;
      }
    }
  }
}