header {
  position: relative;
  z-index: 2;
  .row {
    position: relative;
  }
  .nav-icon {
    position: absolute;
    top: 33px;
    right: 30px;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
    @media (min-width: $medium-break) {
      display: none;
    }
  }
  nav {
    margin-top: 50px;
    @media (max-width: $medium-break) {
      display: none;
      width: 100%;
      margin-top: 0;
      background: #3f413e;
    }
    &.visible {
      @media (max-width: $medium-break) {
        display: block;
      }
    }
    ul {
      margin: 0;
      @media (max-width: $medium-break) {
        margin: 30px 10px 30px 0;
      }
      li {
        display: inline-block;
        padding: 0 8px;
        @media (max-width: $medium-break) {
          display: block;
        }
        a {
          padding: 3px 0;
          @media (max-width: $medium-break) {
            color: #fff;
            &:hover,
            &:visited,
            &:active {
              color: #fff;
            }
          }
          &:hover {
            border-bottom: 3px solid $light-font-color;
          }
        }
      }
    }
  }
}
