section .row,
footer .row {
  &.toggle-viewable-area {
    font-family: $franklin-cond;
    cursor: pointer;
    &.green {
      width: calc(100% + 60px);
      margin: -37px -30px 0;
      @media (min-width: $small-break) {
        display: none;
      }
      div {
        color: #1cbbb4;
        background-color: #173f42;
      }
      hr {
        background: linear-gradient(to bottom, rgba(221, 223, 219, 0) 0%, #173f42 90%, #173f42 100%);
        border-bottom: 1px solid #1cbbb4;
        &:after,
        &:before {
          position: absolute;
          top: 37px;
          width: 30px;
          height: 1px;
          background: #173f42;
          content: ' ';
        }
        &:after {
          left: 0;
        }
        &:before {
          right: 0;
        }
      }
    }
    &.grey {
      div {
        background-color: $secondary-color;
      }
      hr {
        background: linear-gradient(to bottom, rgba(221, 223, 219, 0) 0%, rgba(221, 223, 219, 1) 90%, rgba(221, 223, 219, 1) 100%);
      }
    }
    &.white {
      div {
        color: $primary-color;
        background-color: #fff;
      }
      hr {
        background: linear-gradient(to bottom, rgba(221, 223, 219, 0) 0%, rgba(252, 252, 251, 1) 90%, rgba(255, 255, 255, 1) 100%);
      }
    }
    hr {
      height: 30px;
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid $primary-color;
      border-left: 0;
    }
  }
}

a {
  color: $link-color;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:visited {
    color: $link-color;
  }
  &:hover,
  &:focus {
    color: $link-color;
    border-bottom: 1px solid $link-color;
  }
}

/**** Section Specific ****/
header {
  .fixed {
    box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.25);
  }
}

.main {
  border-bottom: 5px solid rgb(187, 191, 183);
  @media (max-width: $medium-break) {
    border: 0;
  }
}

.about-us {
  background-color: $secondary-color;
  border-top: 1px solid #fff;
}

.services {
  background-size: cover;
  h2 {
    border-bottom: 5px solid rgba(255, 255, 255, 0.15);
  }
}

.choosing-quorum blockquote .quote {
  border-bottom: 5px solid rgba(12, 110, 117, 0.15);
}

.who-we-serve {
  background-color: $secondary-color;
}

footer.contact {
  color: $white-font-color;
  background-color: $dark-font-color;
  .info {
    color: $middle-font-color;
  }
}
