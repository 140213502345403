/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'variables';
@import 'mixins';

// Layout
@import 'vendor/foundation/foundation.scss';
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';

// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/images';
@import 'components/forms';
@import 'components/login';
@import 'components/iebar';

// Vendor
@import 'vendor/font-awesome/4.2.0/scss/font-awesome';

// Extras
@import 'shame';
