header .logo {
  background: transparent url('../images/quorum-logo.svg') no-repeat left center;
  background-size: contain;
  @media (max-width: $medium-break) {
    margin-left: 30px;
  }
}

.services {
  background-image: url('../images/services_background.jpg');
  @media (max-width: $medium-break) {
    background-image: url('../images/services_background-tblt.jpg');
  }
  @media (max-width: $small-break) {
    background-position: center center;
  }
}
